/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

//window.Vue = require('vue');

//Vue.component('chat-component', require('./components/ChatComponent'));

//export const bus = new Vue();

//const app = new Vue({
//    el: '#app'
//});

import $ from 'jquery';
import iziToast from 'izitoast';
import {Bloodhound} from 'corejs-typeahead/index';
import moment from '../../../node_modules/moment/moment.js';
import Swal from 'sweetalert2';

window.$ = window.jQuery = $;

//window.swal = function() {return new Swal(...arguments)};
window.swal = function () {
    const customSwal = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn btn-primary',
            cancelButton: 'btn btn-danger'
        },
        timer: 3000,
        timerProgressBar: true,
        buttonsStyling: false
    })
    return customSwal.fire(...arguments)
};

window.iziToast = iziToast;

window.Bloodhound = Bloodhound;

window.moment = moment;
const customSwal = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn btn-primary',
        cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false,
    target: document.getElementsByClassName('modal-content')[0],
})

window.Swal = Swal;
window.Swal2 = customSwal;

require('./main');
