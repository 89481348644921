tinymce.PluginManager.add('charactercount', function (editor) {
  var self = this;
 
  function update() {
    editor.theme.panel.find('#charactercount').text(['Characters: {0}', self.getCount()]);
  }
 
  editor.on('init', function () {
    var statusbar = editor.theme.panel && editor.theme.panel.find('#statusbar')[0];
 
    if (statusbar) {
      window.setTimeout(function () {
 
        editor.on('setcontent beforeaddundo', update);
 
        editor.on('keyup', function (e) {
            update();
        });
      }, 0);
    }
  });
 
  self.getCount = function () {
    var tx = editor.getContent({ format: 'raw' });
    var decoded = decodeHtml(tx);
    var decodedStripped = decoded.replace(/(<([^>]+)>)/ig, "").trim();
    var tc = decodedStripped.length;
    return tc;
  };
 
  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
});